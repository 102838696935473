
import { MdOutlineAttachMoney } from "react-icons/md";
import { ButtonContent } from "../global/boton/ButtonContent";
// import { GiRibbonMedal, GiPaintRoller } from "react-icons/gi";
// import { AiOutlineTeam } from "react-icons/ai";

function HeroSection({ bgImg, bgImg1, bgImg2, bgImg3, title, texts }) {

  const BgImages = [
    bgImg,
    bgImg1,
    bgImg2,
    bgImg3,
  ]
  return (
    <>
      <div className="pic-wrapper svgHerosection1  w-full h-screen" >
        {
          BgImages.map((item, index) => {
            return (
              <figure
                key={index}
                className={`pic-${index + 1}`}
                style={{ background: `url("${item}")` }}
              ></figure>
            )
          })
        }
        <div className="z-10 relative w-4/5 mx-auto h-full flex flex-col justify-center items-center md:pb-[180px] md:pt-[140px] pt-[150px] pb-[150px]">
          <div className="w-full flex flex-col justify-center items-center">
            <h1 className="text-white text-center text-[35px] md:text-[60px]">
              {title}
            </h1>
            <p className="text-white text-center">
              {texts}
            </p>
          </div>
          <div className="w-full flex justify-center py-5">
            <div className="hidden md:block">
              <ButtonContent btnStyle={'three'} />
            </div>
            <a
              className={`block md:hidden boton three my-3 px-6 py-4 w-52 rounded-lg`}
              target='_blank'
              href={'https://www.yardbook.com/3910/pay_now'}
              rel="noreferrer"
            >
              <span className='flex items-center justify-center'>
                <MdOutlineAttachMoney fontSize={25} />
                Pay Your Invoice
              </span>
            </a>
          </div>
          <div>
            <img className="w-[300px]" src="https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/archivos_varios%2Fsello22%20google.png?alt=media&token=fd79370f-b2ac-414f-a4ce-d538c6ad19d5" alt="google"/>
          </div>
        </div>
      </div>
      {/* <div className=" md:w-[70%] w-[100%] bg-2 text-white md:-mt-10 -mt-20 relative md:py-14 py-2 homi">
        <ul className="flex md:flex-row flex-col justify-around ml-5 md:ml-0">
          <h5 className="flex items-center" data-aos="zoom-in">
            <GiPaintRoller
              fontSize={70}
              className="text-white"
            />
            BEST PAINTING</h5>
          <h5 className="flex items-center" data-aos="zoom-in" data-aos-duration="1000">
            <AiOutlineTeam
              fontSize={70}
              className="text-white"
            />
            PROFESSIONAL TEAM</h5>
          <h5 className="flex items-center" data-aos="zoom-in" data-aos-duration="1500">
            <GiRibbonMedal
              fontSize={70}
              className="text-white"
            />BEST QUALITY</h5>
        </ul>
      </div> */}
    </>
  );
}

export default HeroSection;
